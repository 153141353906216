<template>
  <div>
    <section class="section is-main-section">
      <div class="is-flex mb-3 is-justify-content-end">
        <button @click="addBan" class="button is-success">IP Banla</button>
      </div>
      <card-component title="Banlanan IP'ler">
        <b-table ref="cageTable"
                 :loading="$store.state.loading && $store.state.loading.length > 0"
                 :striped="true"
                 :responsive="true"
                 :hoverable="true"
                 :data="banList">
          <b-table-column label="IP" field="ip" sortable v-slot="props">
            {{ props.row.ip }}
          </b-table-column>
          <b-table-column label="Actions" field="actions" sortable v-slot="props">
            <b-button size="is-small" type="is-info" @click="removeBan(props.row.ip)">Banı kaldır</b-button>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="$store.state.loading &&  $store.state.loading.length > 0">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large"/>
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"/>
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import {ProfileService} from '@/services'
import AddBan from "@/views/User/AddBan.vue";

export default {
  name: 'PlayerReport',
  components: {
    CardComponent
  },
  data() {
    return {
      banList: [],
    }
  },
  mounted() {
    this.bannedList()
  },
  methods: {
    addBan(){
      this.openModal({
        component: AddBan,
        props: {bannedList: this.bannedList},
        close: () => this.bannedList()
      })
    },
    bannedList() {
      ProfileService.bannedList().then(res => {
        this.banList = []
        res.data.forEach((item) => {
          this.banList.push({
            ip: item
          })
        })
      })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    },
    removeBan(ip) {
      ProfileService.removeBan({ip}).then(res => {
        this.$buefy.toast.open({message: "Ban kaldırıldı", position: 'is-top-right', type: 'is-success'})
        this.bannedList()
      })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    }
  }
}
</script>
